<template>
  <div class="auth--login">
    <header>
      <h1 class="text-h mt-6">Bienvenidos</h1>
      <p class="text--secondary">Ingresa con los datos de tu cuenta.</p>
    </header>

    <!-- Form -->
    <v-form :disabled="loading" ref="form" @submit.prevent class="my-9">
      <div class="form-group mb-6">
        <label>Email:</label>
        <v-text-field
          flat
          hide-details
          solo-inverted
          :rules="emailR"
          v-model="email"
        />
      </div>
      <div class="form-group">
        <label>Password:</label>
        <v-text-field
          flat
          hide-details
          autocomplete
          solo-inverted
          v-model="password"
          type="password"
          :rules="required"
        />
      </div>
      <div class="form-group">
        <v-checkbox v-model="checkbox" label="Recordarme" />
      </div>
      <v-btn
        block
        large
        :disabled="loading"
        :loading="loading"
        depressed
        color="primary"
        @click="onSubmit"
      >
        Ingresar
      </v-btn>

      <v-btn block text class="mt-4" :to="{ name: 'Recovery' }">
        Recuperar mi contraseña
      </v-btn>
    </v-form>
  </div>
</template>

<script>
import { auth } from "@/firebase";
import {
  signInWithEmailAndPassword,
  setPersistence,
  browserSessionPersistence,
  browserLocalPersistence,
} from "firebase/auth";
import Rules from "@/mixins/rules";

export default {
  data: () => ({
    email: "",
    password: "",
    checkbox: false,
    loading: false,
  }),

  mixins: [Rules],

  methods: {
    async onSubmit() {
      if (this.$refs.form.validate()) {
        this.loading = true;
        const persistence = this.checkbox
          ? browserLocalPersistence
          : browserSessionPersistence;
        await setPersistence(auth, persistence)
          .then(() => {
            return signInWithEmailAndPassword(auth, this.email, this.password);
          })
          .catch((error) => {
            const errorCode = error.code;
            const errorMessage = error.message;
            console.debug(errorMessage, errorCode);
          });
        this.loading = false;
      }
    },
  },
};
</script>