// src/mixins/rules.js

export default {
  data: () => ({
    required: [v => !!v || 'Obligatorio'],
    checkbox: [v => v.length > 0 || 'Obligatorio'],
    emailR: [
      v => !!v || 'E-mail is obligatorio',
      v => /.+@.+\..+/.test(v) || 'E-mail debe ser valido',
    ],
  })
}